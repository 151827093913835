import axios from "axios";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { Datagrid, ListContextProvider, TextField, useList } from "react-admin";

import { Card, Typography, useTheme } from "@mui/material";
import GenericStatusField from "components/commons/fields/GenericStatusFieldProps";
import { RaRecord } from "react-admin";
import { OptiTaskStatusEnum } from "../opti_enums";

export default function OptiTasksList() {
  const [tasks, setTasks] = useState<any>(null);

  const BASE_URL = process.env.REACT_APP_OPTI_TASKS_API_URL;
  const url = `${BASE_URL}/tasks/datatable`;
  const START = 0;
  const LENGTH = 50;

  const formatTimestamps = useCallback((timestamp: number) => {
    return format(new Date(timestamp * 1000), "dd/MM/yyyy HH:mm:ss");
  }, []);

  const prepareData = useCallback(
    (tasksRaw: any) => {
      if (!tasksRaw) {
        return [];
      }
      return tasksRaw.map((task: any) => {
        return {
          ...task,
          received: formatTimestamps(task.received),
          started: formatTimestamps(task.started),
          succeeded: formatTimestamps(task.succeeded),
          runtime: `${task.runtime.toFixed(2)} sec`,
        };
      });
    },
    [formatTimestamps],
  );

  const fetchTasks = useCallback(async () => {
    const payload = new URLSearchParams({
      draw: "1",
      "columns[0][data]": "name",
      "columns[0][searchable]": "true",
      "columns[0][orderable]": "true",
      "columns[1][data]": "uuid",
      "columns[1][searchable]": "true",
      "columns[2][data]": "state",
      "columns[2][searchable]": "true",
      "columns[2][orderable]": "true",
      "columns[7][data]": "started",
      "order[0][column]": "7",
      "order[0][dir]": "desc",
      start: String(START),
      length: String(LENGTH),
    });

    const response = await axios.post(url, payload);
    const tasksRaw = response.data.data;
    setTasks(prepareData(tasksRaw));
  }, [url, prepareData]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);
  const listContext = useList({ data: tasks });
  const { palette } = useTheme();

  const getTagInfos = useCallback(
    ({ state }: RaRecord) => {
      switch (state) {
        case OptiTaskStatusEnum.SUCCESS:
          return { color: palette.success.main, text: "Success" };
        case OptiTaskStatusEnum.FAILURE:
          return { color: palette.error.main, text: "Failure" };
        case OptiTaskStatusEnum.PENDING:
          return { color: palette.warning.main, text: "Pending" };
        case OptiTaskStatusEnum.STARTED:
          return { color: palette.info.main, text: "Started" };
        default:
          return { color: palette.error.main, text: "Unknown" };
      }
    },
    [palette.success.main, palette.warning.main, palette.error.main, palette.info.main],
  );

  if (!tasks) {
    return null;
  }
  return (
    <ListContextProvider value={listContext}>
      <Card
        sx={{
          mt: 2,
          p: 2,
        }}
      >
        <Typography variant="h6">Optimizer Tasks</Typography>
        {tasks.length ? (
          <Datagrid resource="books" data={tasks} total={LENGTH} bulkActionButtons={false}>
            <TextField source="name" />
            <GenericStatusField source="state" tagInfoHandler={getTagInfos} />
            <TextField source="runtime" />
            <TextField source="received" />
            <TextField source="started" />
            <TextField source="succeeded" />
          </Datagrid>
        ) : (
          <Typography
            variant="body1"
            sx={{
              p: 8,
            }}
            align="center"
            fontStyle={"italic"}
          >
            No tasks found
          </Typography>
        )}
      </Card>
    </ListContextProvider>
  );
}
