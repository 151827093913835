import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { baseResources } from "config_infos";
import { format, parseISO } from "date-fns";
import { rteGroupTypeChoices } from "enums/reactAdminChoices";
import {
  Datagrid,
  DateField,
  Labeled,
  Loading,
  NumberField,
  ReferenceArrayField,
  SelectField,
  ShowButton,
  TextField,
  useGetList,
  useRecordContext,
} from "react-admin";

export default function GroupBasicInfos() {
  return (
    <Box p={2}>
      <Stack direction={"row"} spacing={4}>
        <Labeled source="name">
          <TextField source="name" fullWidth />
        </Labeled>
        <Labeled source="code">
          <TextField source="code" fullWidth />
        </Labeled>
        <Labeled source="code_tm">
          <TextField source="code_tm" fullWidth />
        </Labeled>
        <Labeled source="mode">
          <TextField source="mode" fullWidth />
        </Labeled>
        <Labeled source={"type"}>
          <SelectField source={"type"} choices={rteGroupTypeChoices} />
        </Labeled>
      </Stack>
      <Divider sx={{ p: 2 }} />
      <Stack>
        <Labeled source={"Sites"} m={2}>
          <ReferenceArrayField source="site_ids" reference={baseResources.sites.SITES} label="Sites">
            <Datagrid bulkActionButtons={false} rowClick={"expand"} expand={<SiteStatuses />}>
              <NumberField source="id" />
              <TextField source="name" />
              <TextField source="city" />
              <DateField source="created_at" showTime locales={"fr-FR"} />
              <ShowButton />
            </Datagrid>
          </ReferenceArrayField>
        </Labeled>
      </Stack>
    </Box>
  );
}

const SiteStatuses = () => {
  const { id } = useRecordContext();

  const { data, total, isLoading, error } = useGetList(baseResources.sites.STATUSES, {
    sort: { field: "timestamp", order: "DESC" },
    pagination: { page: 1, perPage: 1 },
    filter: {
      site_id: id,
    },
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  if (total === 0) {
    return <p>No data</p>;
  }
  const status = (data ?? [])[0];

  return status ? (
    <Box bgcolor={"gainsboro"} p={1}>
      <Typography variant="body2">Current Status</Typography>
      <Stack direction={"row"} spacing={4} pl={2}>
        <TextFieldStatus label="Timestamp" value={format(parseISO(status.timestamp), "dd/MM/yyy HH:mm:ss")} />
        <BooleanStatus label="Network" value={status.network} />
        <BooleanStatus label="Control" value={status.control} />
        <BooleanStatus label="Measure" value={status.measure} />
        <BooleanStatus label="Sequence" value={status.sequence} />
        <BooleanStatus label="Power Clamping" value={status.power_clamping} />
        <BooleanStatus label="Aggregator Module" value={status.aggregator_module} />
      </Stack>
    </Box>
  ) : (
    <></>
  );
};

const TextFieldStatus = ({ label, value }: { label: string; value: string }) => {
  return (
    <Grid item container direction={"column"}>
      <Typography variant="caption" fontWeight={"bold"} noWrap>
        {label}
      </Typography>
      <Typography variant="caption">{value}</Typography>
    </Grid>
  );
};

const BooleanStatus = ({ label, value }: { label: string; value: boolean }) => {
  return (
    <Grid item container direction={"column"}>
      <Typography variant="caption" fontWeight={"bold"} noWrap>
        {label}
      </Typography>
      {value ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
    </Grid>
  );
};
