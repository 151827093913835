import { DataProvider } from "react-admin";

import { baseResources } from "config_infos";
import CompositeDataProvider from "../dataproviders";
import nwreServicesDataprovider from "../dataproviders/nwreServicesDataprovider";

// eslint-disable-next-line
const dataProviderServices = Object.entries(baseResources).map(([service, endpoints]) => {
  const { ENV_VAR, ...rest } = endpoints;
  return {
    dataProvider: nwreServicesDataprovider(ENV_VAR) as DataProvider<string>,
    resources: Object.values(rest) as string[],
  };
});

const compositeDataProvider = new CompositeDataProvider(dataProviderServices);

export default compositeDataProvider;
