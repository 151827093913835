import { Box } from "@mui/material";
import axios from "axios";
import { maskModeColors, planningModeColors } from "components/charts/planning/helpers";
import usePlanningsHelpers from "components/charts/planning/PlanningChart/hooks/usePlanningsHelpers";
import SelectRangePicker from "components/commons/pickers/DateRangePicker/SelectRangePicker";
import { baseResources } from "config_infos";
import { addHours, format, formatISO } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import { Identifier, Loading, useRecordContext } from "react-admin";
import ReactApexChart from "react-apexcharts";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { MaskType, PlanningType } from "types";

const PlanningsAndMasks = () => {
  const { site_ids, sites } = useRecordContext();
  const { data, setData, dateRange, setDateRange, fetchAllRessourcesWithinTimeRange } = usePlanningsHelpers(
    new Date(),
    addHours(new Date(), 1),
  );

  const siteIdsMapping = useMemo(() => {
    const mapping: { [key: Identifier]: string } = {};
    sites.forEach((site: any) => {
      mapping[site.id] = site.name;
    });
    return mapping;
  }, [sites]);

  const consolidatePlannings = useCallback(
    (plannings: PlanningType[]) => {
      const planningsByMode: { [key: string]: PlanningType[] } = {};
      plannings.forEach((planning) => {
        if (!planningsByMode[planning.mode]) {
          planningsByMode[planning.mode] = [];
        }
        planningsByMode[planning.mode].push(planning);
      });
      const formattedPlannings = Object.entries(planningsByMode).map(([mode, plannings]) => {
        return {
          name: mode,
          data: plannings.map((planning) => {
            return {
              x: `${siteIdsMapping[planning.site_id]}`,
              y: [new Date(planning.start_date).getTime(), new Date(planning.end_date).getTime()],
            };
          }),
        };
      });
      return formattedPlannings;
    },
    [siteIdsMapping],
  );

  const fetchPlannings = useCallback(async () => {
    const plannings = await fetchAllRessourcesWithinTimeRange(baseResources.plannings.PLANNINGS, undefined, {
      end_date__gt: formatISO(dateRange.startDate),
      start_date__lt: formatISO(dateRange.endDate),
      site_id__in: site_ids,
    });
    return consolidatePlannings(plannings as PlanningType[]);
  }, [fetchAllRessourcesWithinTimeRange, dateRange, site_ids, consolidatePlannings]);

  const consolidateMasks = useCallback((masks: MaskType[]) => {
    const masksByMode: { [key: string]: MaskType[] } = {};
    masks.forEach((mask) => {
      if (!masksByMode[mask.operating_mode]) {
        masksByMode[mask.operating_mode] = [];
      }
      masksByMode[mask.operating_mode].push(mask);
    });
    const formattedMasks = Object.entries(masksByMode).map(([mode, masks]) => {
      return {
        name: mode,
        data: masks.map((mask) => {
          return {
            x: `Group Masks`,
            y: [new Date(mask.start_date).getTime(), new Date(mask.end_date).getTime()],
          };
        }),
      };
    });
    return formattedMasks;
  }, []);

  const fetchMasks = useCallback(async () => {
    if (!site_ids || site_ids.length === 0) {
      return;
    }
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_PLANNINGS_API_URL}/${baseResources.plannings.PLANNING_MASKS}trimmed/`,
      params: {
        start_date: formatISO(dateRange.startDate),
        end_date: formatISO(dateRange.endDate),
        page: 1,
        size: 100,
        site_id__in: site_ids[0],
      },
    });
    return consolidateMasks(data.items as MaskType[]);
  }, [dateRange.startDate, dateRange.endDate, site_ids, consolidateMasks]);

  useEffect(() => {
    Promise.allSettled([fetchMasks(), fetchPlannings()])
      .then((results) => {
        let planningsAndMasks: any = [];
        results.forEach((result) => {
          if (result.status === "rejected") {
            throw new Error(`Failed to fetch data: ${result.reason}`);
          }
          if (result.value) {
            planningsAndMasks = [...planningsAndMasks, ...result.value];
          }
          setData(planningsAndMasks);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  const state = {
    series: data || [], // The data series that will be rendered in the chart. If data is null/undefined, an empty array is used.
    options: {
      chart: {
        parentHeightOffset: 100, // Adds an offset to the height of the chart's parent container.
        type: "bar", // Defines the type of chart, in this case, a "bar" chart.
        toolbar: {
          show: false, // Disables the toolbar (zoom, pan, reset options) for the chart.
        },
      },
      title: {
        text: "Plannings and Masks", // Sets the title of the chart to "Plannings and Masks".
        align: "center", // Aligns the title to the center of the chart.
      },
      plotOptions: {
        bar: {
          horizontal: true, // Sets the bars to be horizontal instead of vertical.
          barHeight: "75%", // Adjusts the height of the bars relative to the total chart height.
          rangeBarGroupRows: true, // Groups range bars that share the same x-axis label (good for Gantt-like charts).
          borderRadius: 5, // Sets the border radius of the bars to 5 pixels.
        },
      },
      colors: data
        ? data.map((serie: any) => {
            return Object.keys(planningModeColors).includes(serie.name)
              ? planningModeColors[serie.name]
              : maskModeColors[serie.name];
          })
        : "", // Maps the series data to specific colors based on the name of the series.
      xaxis: {
        type: "datetime", // Specifies that the x-axis will represent date/time values.
        labels: {
          formatter: function (value: number) {
            return format(new Date(value), "dd/MM/yyyy HH:mm"); // Customizes the label format on the x-axis to display dates in "dd/MM/yyyy HH:mm" format.
          },
          rotate: -45, // Rotates the x-axis labels by -45 degrees for better readability.
          rotateAlways: true, // Ensures that labels are always rotated, even if there's enough space for them without rotation.
          style: {
            fontSize: "8px", // Sets the font size of the x-axis labels to 8px.
          },
        },
        min: dateRange.startDate.getTime(), // The minimum value of the x-axis (start date/time in milliseconds).
        max: dateRange.endDate.getTime(), // The maximum value of the x-axis (end date/time in milliseconds).
      },
      grid: {
        padding: {
          bottom: 50, // Adds padding at the bottom of the chart to prevent labels from being clipped.
        },
      },
      stroke: {
        width: 2, // Sets the stroke (border) width of each bar to 2 pixels.
      },
      fill: {
        type: "solid", // Sets the fill style for bars to solid.
        opacity: 0.6, // Adjusts the opacity of the fill color for bars to 60%.
      },
      legend: {
        position: "top", // Positions the legend at the top of the chart.
        horizontalAlign: "left", // Aligns the legend items to the left horizontally.
      },
      tooltip: {
        x: {
          formatter: function (siteName: string) {
            if (!Number.isNaN(parseFloat(siteName))) {
              return format(new Date(siteName), "dd/MM/yyyy HH:mm"); // Formats tooltip x-axis value as a date if it's a number.
            }
            return `${siteName}`; // Otherwise, returns the string value (site name).
          },
        },
      },
    },
  };

  const handleSubmit: SubmitHandler<FieldValues> = ({ startDate, dateRange }) => {
    setDateRange({
      startDate: new Date(startDate),
      endDate: addHours(new Date(startDate), +dateRange),
    });
  };

  const MISC_CHART_HEIGHT = 130;
  const BAR_HEIGHT = 40;
  const chartHeight = MISC_CHART_HEIGHT + site_ids.length * BAR_HEIGHT;

  return (
    <Box>
      <SelectRangePicker handleSubmit={handleSubmit} />
      {data ? (
        <ReactApexChart options={state.options as any} series={state.series} type="rangeBar" height={chartHeight} />
      ) : (
        <Loading />
      )}
    </Box>
  );
};

export default PlanningsAndMasks;
