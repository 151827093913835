export const WEBHOOKS_TOPIC_ACTIONS = ["create", "update", "delete"];

export const WEBHOOKS_TOPIC_ENTITIES = ["site", "group"];

export const PLANNING_MODE = [
  "soc_management",
  "fcr_rte_v2",
  "fcr_rte_v2_no_reserve",
  "stop",
  "power_chronicle",
  "power",
  "afrr_rte",
  "afrr_fcr_rte",
  "fingrid_fcrn",
];

export const PLANNING_MODE_ENUM = {
  soc_management: "soc_management",
  fcr_rte_v2: "fcr_rte_v2",
  fcr_rte_v2_no_reserve: "fcr_rte_v2_no_reserve",
  afrr_rte: "afrr_rte",
  afrr_fcr_rte: "afrr_fcr_rte",
  power_chronicle: "power_chronicle",
  power: "power",
  stop: "stop",
  fingrid_fcrn: "fingrid_fcrn",
};

export const PLANNING_MASK_MODE_ENUM = {
  fcr_standalone: "fcr_standalone",
  r2_standalone: "r2_standalone",
  r2_tests: "r2_tests",
  r2_chronicles: "r2_chronicles",
  r1_chronicles: "r1_chronicles",
  enspired: "enspired",
  r1_r2_mask: "r1_r2_mask",
  technical_chronicle: "technical_chronicle",
  arbitrage_spot: "arbitrage_spot",
  fake_box_staging: "fake_box_staging",
  standby_france: "standby_france",
  fcrn_standalone: "fcrn_standalone",
  fcrd_standalone: "fcrd_standalone",
  fcrd_fcrn_combined: "fcrd_fcrn_combined",
  r2_finland: "r2_finland",
  standby_finland: "standby_finland",
};

export const PLANNING_MASK_MODE = Object.values(PLANNING_MASK_MODE_ENUM);

export const SCHEDULER_TYPE = ["interval", "crontab"];

export const SITES_TASKS = ["archive_telemetry", "check_sites_statuses_task"];

export const PLANNING_TASKS = ["send_plannings"];

export const IEC_TASKS = ["worker.iec.tasks.process_TM_task_v2", "worker.iec.tasks.refresh_site_service_data_task"];

export const IEC_HEALTH_CHECK = ["Healthy", "Non_healthy"];

export const TASK_PERIOD = ["days", "hours", "minutes", "seconds", "microseconds"];

export const TENDER_RESULT = ["NONE_AVAILABLE", "REGULAR", "FALLBACK"];

export const TENDER_STATE = ["OPEN", "CLOSED", "CANCELED", "FINISHED"];

export const MARKET = ["CAPACITY", "ENERGY"];

export const PRODUCT_TYPE = ["FCR", "aFRR", "mFRR"];

export const MEASURE_UNIT = ["MAW", "MWH"];

export const BIDS_CURRENCY = ["EUR"];

export const BIDS_PRICES_PAYMENT_DIRECTION = ["GRID_TO_PROVIDER", "PROVIDER_TO_GRID"];

export const BIDS_PRICES_TYPE = ["OFFER", "SETTLEMENT"];

export const BIDS_CONDITIONAL_LINKAGE_ITEM_CONDITION = ["LC_01", "LC_02", "LC_03", "LC_04", "LC_05", "LC_06"];

export const BIDS_ZONE_TYPE = ["LFC_AREA", "COUNTRY", "LFC_BLOCK"];

export const BIDS_ZONES = [
  "10YFR-RTE------C",
  "10Y1001C--00100H",
  "10Y1001C--000182",
  "10YUA-WEPS-----0",
  "10Y1001A1001A869",
  "10Y1001C--00003F",
  "10YTR-TEIAS----W",
  "10YDE-ENBW-----N",
  "10YDE-EON------1",
  "10YCH-SWISSGRIDZ",
  "10YSE-1--------K",
  "10YES-REE------0",
  "10Y1001A1001A016",
  "10YSI-ELES-----O",
  "10YSK-SEPS-----K",
  "10YCS-SERBIATSOV",
  "10Y1001A1001A47J",
  "10Y1001A1001A46L",
  "10Y1001A1001A45N",
  "10Y1001A1001A44P",
  "10Y1001A1001A49F",
  "10YRO-TEL------P",
  "10Y1001A1001A990",
  "10YPT-REN------W",
  "10YPL-AREA-----S",
  "10YDOM-1001A082L",
  "10YNO-0--------C",
  "10Y1001A1001A48H",
  "10YNO-4--------9",
  "10YNO-3--------J",
  "50Y0JVU59B4JWQCU",
  "10YNO-2--------T",
  "10YNO-1--------2",
  "10YNL----------L",
  "10Y1001A1001A92E",
  "11Y0-0000-0265-K",
  "17Y0000009369493",
  "10Y1001C--00098F",
  "10YGB----------A",
  "10YCS-CG-TSO---S",
  "10Y1001A1001A93C",
  "10YLU-CEGEDEL-NQ",
  "10YLT-1001A0008Q",
  "10YLV-1001A00074",
  "10Y1001A1001A50U",
  "10Y1001A1001A788",
  "10Y1001A1001A75E",
  "10Y1001A1001A74G",
  "10Y1001A1001A77A",
  "10Y1001A1001A76C",
  "10Y1001A1001A67D",
  "10Y1001A1001A81J",
  "10Y1001A1001A68B",
  "10Y1001A1001A80L",
  "10Y1001A1001A73I",
  "10Y1001A1001A877",
  "10Y1001A1001A85B",
  "10Y1001A1001A84D",
  "10Y1001A1001A66F",
  "10Y1001A1001A72K",
  "10Y1001A1001A71M",
  "10Y1001A1001A70O",
  "10Y1001A1001A699",
  "10Y1001A1001A893",
  "10Y1001C--00096J",
  "10Y1001A1001A885",
  "10YIT-GRTN-----B",
  "10YIE-1001A00010",
  "10Y1001A1001A59C",
  "IS",
  "10YHU-MAVIR----U",
  "10YGR-HTSO-----Y",
  "10Y1001A1001A83F",
  "10YMK-MEPSO----8",
  "10YFI-1--------U",
  "10Y1001A1001A39I",
  "10Y1001A1001A796",
  "10YDK-2--------M",
  "10YDK-1--------W",
  "10Y1001A1001A65H",
  "10Y1001A1001A82H",
  "10Y1001A1001A63L",
  "10YCZ-CEPS-----N",
  "10YCY-1001A0003J",
  "10YDOM-REGION-1V",
  "10YHR-HEP------M",
  "10YDOM-CZ-DE-SKK",
  "10YCA-BULGARIA-R",
  "10YBA-JPCC-----D",
  "10YBE----------2",
  "10Y1001A1001A51S",
  "10YAT-APG------L",
  "10YDE-RWENET---I",
  "10YAL-KESH-----5",
  "10YDE-VE-------2",
];

export const BIDS_TYPE = ["SIMPLE", "MULTIPART", "MULTIPART_COMPONENT", "EXCLUSIVE", "EXCLUSIVE_COMPONENT", "GROUP"];

export const BIDS_DIRECTION = ["NEG", "POS", "NEGPOS"];

export const BIDS_ACTIVATION = ["DIRECT", "SCHEDULED"];

export const BIDS_PRODUCT_NAME = [
  "NEGPOS_00_04",
  "NEGPOS_04_08",
  "NEGPOS_08_12",
  "NEGPOS_12_16",
  "NEGPOS_16_20",
  "NEGPOS_20_24",
  "NEGPOS_00_24",
  "NEG_00_04",
  "NEG_04_08",
  "NEG_08_12",
  "NEG_12_16",
  "NEG_16_20",
  "NEG_20_24",
  "POS_00_04",
  "POS_04_08",
  "POS_08_12",
  "POS_12_16",
  "POS_16_20",
  "POS_20_24",
  "NEG_001",
  "NEG_002",
  "NEG_003",
  "NEG_004",
  "NEG_005",
  "NEG_006",
  "NEG_007",
  "NEG_008",
  "NEG_009",
  "NEG_010",
  "NEG_011",
  "NEG_012",
  "NEG_013",
  "NEG_014",
  "NEG_015",
  "NEG_016",
  "NEG_017",
  "NEG_018",
  "NEG_019",
  "NEG_020",
  "NEG_021",
  "NEG_022",
  "NEG_023",
  "NEG_024",
  "NEG_025",
  "NEG_026",
  "NEG_027",
  "NEG_028",
  "NEG_029",
  "NEG_030",
  "NEG_031",
  "NEG_032",
  "NEG_033",
  "NEG_034",
  "NEG_035",
  "NEG_036",
  "NEG_037",
  "NEG_038",
  "NEG_039",
  "NEG_040",
  "NEG_041",
  "NEG_042",
  "NEG_043",
  "NEG_044",
  "NEG_045",
  "NEG_046",
  "NEG_047",
  "NEG_048",
  "NEG_049",
  "NEG_050",
  "NEG_051",
  "NEG_052",
  "NEG_053",
  "NEG_054",
  "NEG_055",
  "NEG_056",
  "NEG_057",
  "NEG_058",
  "NEG_059",
  "NEG_060",
  "NEG_061",
  "NEG_062",
  "NEG_063",
  "NEG_064",
  "NEG_065",
  "NEG_066",
  "NEG_067",
  "NEG_068",
  "NEG_069",
  "NEG_070",
  "NEG_071",
  "NEG_072",
  "NEG_073",
  "NEG_074",
  "NEG_075",
  "NEG_076",
  "NEG_077",
  "NEG_078",
  "NEG_079",
  "NEG_080",
  "NEG_081",
  "NEG_082",
  "NEG_083",
  "NEG_084",
  "NEG_085",
  "NEG_086",
  "NEG_087",
  "NEG_088",
  "NEG_089",
  "NEG_090",
  "NEG_091",
  "NEG_092",
  "NEG_093",
  "NEG_094",
  "NEG_095",
  "NEG_096",
  "NEG_097",
  "NEG_098",
  "NEG_099",
  "NEG_100",
  "POS_001",
  "POS_002",
  "POS_003",
  "POS_004",
  "POS_005",
  "POS_006",
  "POS_007",
  "POS_008",
  "POS_009",
  "POS_010",
  "POS_011",
  "POS_012",
  "POS_013",
  "POS_014",
  "POS_015",
  "POS_016",
  "POS_017",
  "POS_018",
  "POS_019",
  "POS_020",
  "POS_021",
  "POS_022",
  "POS_023",
  "POS_024",
  "POS_025",
  "POS_026",
  "POS_027",
  "POS_028",
  "POS_029",
  "POS_030",
  "POS_031",
  "POS_032",
  "POS_033",
  "POS_034",
  "POS_035",
  "POS_036",
  "POS_037",
  "POS_038",
  "POS_039",
  "POS_040",
  "POS_041",
  "POS_042",
  "POS_043",
  "POS_044",
  "POS_045",
  "POS_046",
  "POS_047",
  "POS_048",
  "POS_049",
  "POS_050",
  "POS_051",
  "POS_052",
  "POS_053",
  "POS_054",
  "POS_055",
  "POS_056",
  "POS_057",
  "POS_058",
  "POS_059",
  "POS_060",
  "POS_061",
  "POS_062",
  "POS_063",
  "POS_064",
  "POS_065",
  "POS_066",
  "POS_067",
  "POS_068",
  "POS_069",
  "POS_070",
  "POS_071",
  "POS_072",
  "POS_073",
  "POS_074",
  "POS_075",
  "POS_076",
  "POS_077",
  "POS_078",
  "POS_079",
  "POS_080",
  "POS_081",
  "POS_082",
  "POS_083",
  "POS_084",
  "POS_085",
  "POS_086",
  "POS_087",
  "POS_088",
  "POS_089",
  "POS_090",
  "POS_091",
  "POS_092",
  "POS_093",
  "POS_094",
  "POS_095",
  "POS_096",
  "POS_097",
  "POS_098",
  "POS_099",
  "POS_100",
];

export const GROUP_TYPES_ENUM = {
  rte_edr: "rte_edr",
  rte_edp: "rte_edp",
  rte_edc: "rte_edc",
  rte_eda: "rte_eda",
};

export const GROUP_TYPES = [
  { value: "rte_edr", name: "EDR" },
  { value: "rte_edp", name: "EDP" },
  { value: "rte_edc", name: "EDC" },
  { value: "rte_eda", name: "EDA" },
];

export const COUNTRIES = [
  {
    value: "us",
    name: "USA",
  },
  {
    value: "fin",
    name: "Finland",
  },
  {
    value: "swe",
    name: "Sweden",
  },
  {
    value: "fr",
    name: "France",
  },
];

// export const SITE_COUNTRIES = [
//   {
//     id: "Finland",
//     name: "Finland",
//   },
//   {
//     id: "France",
//     name: "France",
//   },
// ];

export const SITE_MODES = [
  { id: "test", name: "Test" },
  { id: "factory_trials", name: "Factory trials" },
  { id: "operational_trials", name: "Operational trials" },
  { id: "tso_trials", name: "Tso trials" },
  { id: "production", name: "Production" },
];

export const GROUP_MODES = [
  { id: "prod", name: "Production" },
  { id: "test", name: "Test" },
];

// export const SITE_DSO = [
//   { id: "enedis", name: "enedis" },
//   { id: "geredis", name: "geredis" },
// ];

// export const SITE_SUPPLIER = [
//   { id: "NWRE", name: "NWRE" },
//   { id: "ELMY", name: "ELMY" },
// ];

export const RTE_FCR_COMPUTATION_VERSION = [
  { id: 1, name: "FROM_AUTOMATON_CONFIGURATION" },
  { id: 2, name: "FROM_AUTOMATON_COMPUTATION" },
];

export const SITE_OPERATOR = [
  { id: "NW JMET", name: "NW JMET" },
  { id: "NW JMET2", name: "NW JMET2" },
  { id: "NW JMET3", name: "NW JMET3" },
];

export const MAINTENANCE_TYPE = [
  { id: "enedis", name: "Enedis" },
  { id: "preventive", name: "Preventive" },
  { id: "fortuit", name: "Fortuit" },
  { id: "re", name: "RE" },
];

export const CHRONICLE_FILENAMES = [
  { id: "chronique_41h_v3.csv", name: "chronique_41h_v3.csv" },
  { id: "essai_1bis.csv", name: "essai_1bis.csv" },
  { id: "essai_2v2.csv", name: "essai_2v2.csv" },
  { id: "essai_3v2.csv", name: "essai_3v2.csv" },
  { id: "essai_4v2.csv", name: "essai_4v2.csv" },
  { id: "essai_5.csv", name: "essai_5.csv" },
  { id: "chronique_32h.csv", name: "chronique_32h.csv" },
  { id: "chronique_36h.csv", name: "chronique_36h.csv" },
  { id: "r2_essai_2_file1.csv", name: "r2_essai_2_file1.csv" },
  { id: "r2_essai_2_file2.csv", name: "r2_essai_2_file2.csv" },
  { id: "r2_essai_1_file1.csv", name: "r2_essai_1_file1.csv" },
  { id: "r2_essai_1_file2.csv", name: "r2_essai_1_file2.csv" },
  { id: "yearly_performance_evaluation_10_racks_jbox1.csv", name: "yearly_performance_evaluation_10_racks_jbox1.csv" },
  { id: "yearly_performance_evaluation_8_racks_jbox1.csv", name: "yearly_performance_evaluation_8_racks_jbox1.csv" },
];
// export const CHRONICLE_FILENAMES_ENUM = {
//   chronique_41h_v3: "chronique_41h_v3.csv",
//   essai_1bis: "essai_1bis.csv",
//   essai_2v2: "essai_2v2.csv",
//   essai_3v2: "essai_3v2.csv",
//   essai_4v2: "essai_4v2.csv",
//   essai_5: "essai_5.csv",
//   chronique_32h: "chronique_32h.csv",
//   chronique_36h: "chronique_36h.csv",
//   r2_essai_2_file1: "r2_essai_2_file1.csv",
//   r2_essai_2_file2: "r2_essai_2_file2.csv",
//   r2_essai_1_file1: "r2_essai_1_file1.csv",
//   r2_essai_1_file2: "r2_essai_1_file2.csv",
//   yearly_performance_evaluation_10_racks_jbox1: "yearly_performance_evaluation_10_racks_jbox1.csv",
//   yearly_performance_evaluation_8_racks_jbox1: "yearly_performance_evaluation_8_racks_jbox1.csv",
// };
