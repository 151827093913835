import {
  Datagrid,
  DateField,
  Labeled,
  NumberField,
  ReferenceArrayField,
  SelectField,
  Show,
  ShowActions,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import { useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { baseResources } from "config_infos";
import { subDays } from "date-fns";

import PlanningChart from "components/charts/planning/PlanningChart";
import TelemetryChart from "components/charts/TelemetryChart";
import { MaintenancesList } from "components/entities/maintenances/MaintenancesList";
import { StatusesList } from "components/entities/site_service/sites_statuses/StatusesList";
import { SITE_MODES } from "enums";
import "react-big-scheduler-stch/lib/css/style.css";
import SiteConfigurations from "./SiteConfigurations";
import SiteLocation from "./SiteLocation";

type LabeledFieldProps = {
  source: string;
  label?: string;
  ComponentField?: React.ComponentType<any>;
};

const LabeledField = ({ source, label, ComponentField = TextField }: LabeledFieldProps) => (
  <Labeled source={source} label={label || source}>
    <ComponentField source={source} fullWidth />
  </Labeled>
);

export const SiteShow = () => {
  const { id } = useParams();

  return (
    <Show actions={<ShowActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="General Infos">
          <Typography variant="h6" gutterBottom>
            {"Site Infos"}
          </Typography>
          <Box display={"grid"} gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" }} gap={2}>
            <LabeledField source="name" />
            <LabeledField source="dso" />
            <LabeledField source="rte_code" label={"Tranche TM RTE"} />
            <LabeledField source="email" />
            <LabeledField source="city" />
            <LabeledField source="zipcode" />
            <LabeledField source="country" />
            <Labeled source="mode">
              <SelectField source={"mode"} choices={SITE_MODES} />
            </Labeled>
            <LabeledField source="soc_reserve_inf" ComponentField={NumberField} />
            <LabeledField source="soc_inf" ComponentField={NumberField} />
            <LabeledField source="soc_sup" ComponentField={NumberField} />
            <LabeledField source="soc_reserve_up" ComponentField={NumberField} />
            <LabeledField source="useful_energy_at_1mw" ComponentField={NumberField} />
          </Box>
          <Typography variant="h6" gutterBottom>
            {"Group Infos"}
          </Typography>
          <ReferenceArrayField source="group_ids" reference={baseResources.sites.GROUPS}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" />
              <TextField source="code" />
              <DateField source="created_at" />
            </Datagrid>
          </ReferenceArrayField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Charts" path="charts">
          <Typography variant="h6" gutterBottom>
            Telemetry
          </Typography>
          <TelemetryChart siteId={id} />
          <Typography variant="h6" gutterBottom>
            Plannings and Masks
          </Typography>
          <PlanningChart siteId={id} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="maintenances" path="maintenances">
          <MaintenancesList
            resource={baseResources.maintenance.MAINTENANCE}
            filter={{ site_id: id, end__gt: subDays(new Date(), 7).toISOString() }}
            disableSynWithLocation
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="statuses" path="statuses">
          <StatusesList
            resource={baseResources.sites.STATUSES}
            filter={{ site_id: id }}
            isSite={true}
            disableSyncWithLocation
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Configurations" path="configurations">
          <SiteConfigurations />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Map" path="siteMap">
          <SiteLocation />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
