import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateField,
  DateTimeInput,
  EditButton,
  Labeled,
  List,
  NumberInput,
  RaRecord,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectField,
  ShowButton,
  SingleFieldList,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { baseResources } from "config_infos";
import { SITE_MODES } from "enums";
import ColorTag from "components/commons/tags/ColorTag";
import { allDsoChoices, siteCountryChoices } from "enums/reactAdminChoices";

type InputContainerProps = {
  source: string;
  Field?: React.ComponentType<any>;
  labelCustomProps?: any;
  fieldCustomProps?: any;
};

const InputContainer = ({ source, labelCustomProps, fieldCustomProps, Field = TextField }: InputContainerProps) => (
  <Box flex={1} pl={2} pr={2}>
    <Labeled source={source} {...labelCustomProps}>
      <Field source={source} fullWidth {...fieldCustomProps} />
    </Labeled>
  </Box>
);

const SiteRowExpand = () => {
  const record = useRecordContext();
  const siteModeOptions = useMemo(() => SITE_MODES.map(({ id, name }: any) => ({ id, name })), []);
  if (!record) return null;

  return (
    <Box display={{ xs: "block", sm: "flex" }}>
      <InputContainer source="valorization_start_date" Field={DateField} />
      <InputContainer source="zipcode" />
      <InputContainer source="efficiency" />
      <InputContainer source="aggregator_module_healthcheck_url" />
      <InputContainer source="network_ip" />
      <InputContainer source="mode" Field={SelectField} fieldCustomProps={{ choices: siteModeOptions }} />
      <InputContainer source="useful_energy_at_1mw" />
    </Box>
  );
};

export const SiteList = () => {
  const filters = useMemo(
    () => [
      <ReferenceArrayInput
        source="id__in"
        key="id__in"
        reference={baseResources.sites.SITES}
        label="Site Selection"
        alwaysOn
      >
        <AutocompleteArrayInput optionValue="id" label="Site Selection" />
      </ReferenceArrayInput>,
      <TextInput source="city" key="city" label="City" />,
      <AutocompleteInput source="country" key="country" label="Country" choices={siteCountryChoices} />,
      <TextInput source="zipcode" key="zipcode" label="Zip Code" />,
      <NumberInput source="max_cycles" key="max_cycles" label="Max Cycles" />,
      <NumberInput source="max_cycles__lt" key="max_cycles__lt" label="Max Cycles (Less Than)" />,
      <NumberInput source="max_cycles__gt" key="max_cycles__gt" label="Max Cycles (Greater Than)" />,
      <DateTimeInput source="valorization_start_date" key="valorization_start_date" label="Valorization Start Date" />,
      <DateTimeInput
        source="valorization_start_date__lt"
        key="valorization_start_date__lt"
        label="Start Date (Before)"
      />,
      <DateTimeInput
        source="valorization_start_date__gt"
        key="valorization_start_date__gt"
        label="Start Date (After)"
      />,
      <TextInput source="public_ip" key="public_ip" label="Public IP" />,
      <TextInput
        source="aggregator_module_healthcheck_url"
        key="aggregator_module_healthcheck_url"
        label="Healthcheck URL"
      />,
      <TextInput source="network_ip" key="network_ip" label="Network IP" />,
      <AutocompleteInput source="dso" key="dso" label="DSO" choices={allDsoChoices} />,
      <TextInput source="rte_code" key="rte_code" label="RTE Code" />,
      <NumberInput source="rack_count" key="rack_count" label="Rack Count" />,
      <NumberInput source="min_power_mw" key="min_power_mw" label="Min Power (MW)" />,
      <NumberInput source="max_power_mw" key="max_power_mw" label="Max Power (MW)" />,
      <NumberInput source="efficiency" key="efficiency" label="Efficiency" />,
      <NumberInput source="latitude" key="latitude" label="Latitude" />,
      <NumberInput source="longitude" key="longitude" label="Longitude" />,
      <NumberInput source="max_soc_mwh" key="max_soc_mwh" label="Max SOC (MWh)" />,
      <NumberInput source="soh" key="soh" label="SOH" />,
      <NumberInput source="useful_energy_at_1mw" key="useful_energy_at_1mw" label="Useful Energy at 1MW" />,
      <NumberInput source="soc_inf" key="soc_inf" label="SOC Inf" />,
      <NumberInput source="soc_sup" key="soc_sup" label="SOC Sup" />,
      <NumberInput source="soc_reserve_inf" key="soc_reserve_inf" label="SOC Reserve Inf" />,
      <NumberInput source="soc_reserve_sup" key="soc_reserve_sup" label="SOC Reserve Sup" />,
      <TextInput source="prm" key="prm" label="PRM" />,
      <TextInput source="site_operator" key="site_operator" label="Site Operator" />,
      <DateTimeInput source="created_at" key="created_at" label="Created At" />,
      <TextInput source="supplier" key="supplier" label="Supplier" />,
      <BooleanInput source="iecharge" key="iecharge" label="IE Charge" />,
      <NumberInput source="rte_fcr_computation_version" key="rte_fcr_computation_version" label="RTE FCR Version" />,
    ],
    [],
  );
  return (
    <List sort={{ field: "created_at", order: "DESC" }} filters={filters}>
      <Datagrid
        sort={{ field: "created_at", order: "DESC" }}
        rowClick="expand"
        expand={<SiteRowExpand />}
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-expandedPanel": {
            backgroundColor: "lightGrey",
          },
        }}
      >
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceArrayField source="group_ids" reference={baseResources.sites.GROUPS} sortable={false}>
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="country" />
        <TextField source="dso" />
        <BooleanField source="iecharge" label="IECharge" />
        <TextField source="rte_code" label={"Tranche TM RTE"} />
        <TextField source="site_operator" />
        <StatusField source="configuration_status" sortable={false} />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const StatusField = ({ source, sortable = false }: { source: string; sortable?: boolean }) => {
  const record = useRecordContext({ source, sortable });
  const theme = useTheme();

  const getTagInfos = useCallback(
    (currentSite: RaRecord) => {
      if (
        !currentSite?.desired_configuration ||
        !currentSite?.reported_configuration ||
        currentSite?.desired_configuration?.id !== currentSite?.reported_configuration?.id
      ) {
        return { color: theme.palette.warning.main, text: "Pending Synchronization" };
      }
      return { color: theme.palette.success.main, text: "Synchronized" };
    },
    [theme.palette.success.main, theme.palette.warning.main],
  );
  const tagInfos = getTagInfos(record);

  return record ? (
    <Stack direction={"row"} spacing={1}>
      <ColorTag color={tagInfos.color} />
      <Typography variant="body2">{tagInfos.text}</Typography>
    </Stack>
  ) : null;
};

export default StatusField;
